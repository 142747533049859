import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../resources/logo.png';  // Assuming the logo.png file is in the same directory
import Box from '@mui/material/Box';

function Appbar() {
  return (
    <AppBar position="static" style={{backgroundColor: 'black', margin:'0px',
     minHeight: '220px', alignItems: 'center'}} >
      <Toolbar>
        <Box>
          <img src={logo} alt="logo" style={{ height: '170px' }} />
        </Box>

        <Box sx={{ position: 'absolute', left: '50%', top: '95%', transform: 'translate(-50%, 0)' }}>
          <Button color="inherit" style={{fontFamily:'Morpheus', fontSize:'16px'}} component={RouterLink} to="/">Home</Button>
          <Button color="inherit" style={{fontFamily:'Morpheus', fontSize:'16px'}} component={RouterLink} to="/about">About</Button>
          <Button color="inherit" style={{fontFamily:'Morpheus', fontSize:'16px'}} component={RouterLink} to="/terms">Contact</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
