import React from 'react';
import AudioPlayer from '../components/AudioPlayer';

function LandingPage() {

  function GetName() {
    let url = "";
    // url = process.env.PUBLIC_URL + 'http://localhost:3000/seroquelhaze.wav';
    // console.log("https://badmoonzrecords.s3.us-east-2.amazonaws.com/seroquelhaze.wav");

    return url;
  }

  return (
    <div style={{
      marginRight: '20px',
      marginLeft: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '25px',
      textAlign: 'center' // This is for horizontal centering
    }}>

      <h1>Newest Release Out - HAV@NA </h1>
<AudioPlayer url={'https://youtu.be/DeZ2TW8m7FM'} />
      <h1 style={{fontFamily:'Metropolitan', fontSize:'32px', marginTop:'30px',
       marginBottom:'0px'}}>Organized Chaos</h1>
      <div style={{fontSize:'20px'}}>
        Bad Moonz Records is a revolutionary music production entity, sculpted from a brutal symphony of creative meltdowns. We operate on the cutting-edge,
        carving out dissonantly beautiful chaos in the realm of music.


{/* <p>
        <iframe allow="autoplay *; encrypted-media *;" frameborder="0" height="250" 

      style={{width:'50%', backgroundColor:'transparent'}} 
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" 
      src="https://embed.music.apple.com/us/album/h3inz/1641527204?i=1641527205"></iframe>
</p> */}

    
      </div>
    </div>
  );
}

export default LandingPage;
