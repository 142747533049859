import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import TermsPage from "./pages/TermsPage";
import NotFoundPage from './pages/NotFoundPage';
import Navbar from "./components/Navbar";
import Appbar from "./components/Appbar";
function App() {
  return (
<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
  <Router>
    <Appbar /> {/* Navbar added */}
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Router>

  <div style={{backgroundColor:'black', marginTop: 'auto', textAlign: 'center', 
    fontFamily:'QuicksandLight', fontSize:'16px', padding:'10px' }} >
    Obsolete media outlets can reach our automata at
    <span>
      <a href="mailto:kingkurl@badmoonzrecords.com" style={{ color: 'red', marginLeft: '5px' }}>info@badmoonzrecords.com</a>
    </span>
  </div>
</div>
  );
}

export default App;
