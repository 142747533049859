import React from 'react';
import ReactPlayer from 'react-player';

function AudioPlayer({ url }) {
  return <div style={{display: 'flex', justifyContent: 'center'}}>
  <ReactPlayer url={url} controls={true} />
</div>

}

export default AudioPlayer;
